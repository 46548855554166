import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';

const OrderRow = ({ order, i }) => {
    const [invoiceLoading, setInvoiceLoading] = useState(false)
    const [labelLoading, setLabelLoading] = useState(false)
    
    const currentStatus = order.attributes.status
    const [newStatus, setNewStatus] = useState(currentStatus)
    const [statusLoading, setStatusLoading] = useState(false)

    const downloadInvoice = (order) => {
        setInvoiceLoading(true)
        const formattedOrder = { ...order.attributes, id: order.id }
        axios.post("/api/orders/invoice", {
            data: formattedOrder
        }, {
            responseType: 'blob',
            headers: {
                "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWUwMzE1MDQyMmFhNGIwMDI4MTJjZDdmIn0sImlhdCI6MTY2NDk3NjUxOX0.wlsQHICTcv17qta-W_3oRSfhbkOmIsrlZ6DS-7ZvyQk",
                "api-key": "de1fbd60-b095-40fc-a040-af58528a9164",
                "secret-key": "4b8c1f3b-ce31-4ea5-8395-a02fed773ab0"
            }
        })
            .then(res => {
                console.log("success")
                const invoice = new Blob(
                    [res.data],
                    { type: 'application/pdf' });

                //Build a URL from the file
                const fileURL = URL.createObjectURL(invoice);
                setInvoiceLoading(false)
                //Open the URL on new Window
                window.open(fileURL);
            }).catch(err => {
                console.error(err)
                setInvoiceLoading(false)
            })
    }

    const handleStatusChange = () => {
        setStatusLoading(true)
        axios.post("/api/orders/update-status", {
            data: {
                orderID: order.attributes.orderID,
                status: order.attributes.status,
                newStatus: newStatus
            }
        }, {
            headers: {
                "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWUwMzE1MDQyMmFhNGIwMDI4MTJjZDdmIn0sImlhdCI6MTY2NDk3NjUxOX0.wlsQHICTcv17qta-W_3oRSfhbkOmIsrlZ6DS-7ZvyQk",
                "api-key": "de1fbd60-b095-40fc-a040-af58528a9164",
                "secret-key": "4b8c1f3b-ce31-4ea5-8395-a02fed773ab0"
            }
        }).then(res => {
            window.location = "/orders"
        }).catch(error => {
            console.log(error)
            setStatusLoading(false)
        })
    }

    const downloadLabel = (orderID) => {
        setLabelLoading(true)
        axios.post("/api/shipping/skyex/create-label", {
            data: {
                'orderID': orderID
            }
        }, {
            responseType: 'blob',
            headers: {
                "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWUwMzE1MDQyMmFhNGIwMDI4MTJjZDdmIn0sImlhdCI6MTY2NDk3NjUxOX0.wlsQHICTcv17qta-W_3oRSfhbkOmIsrlZ6DS-7ZvyQk",
                "api-key": "de1fbd60-b095-40fc-a040-af58528a9164",
                "secret-key": "4b8c1f3b-ce31-4ea5-8395-a02fed773ab0"
            }
        })
            .then(res => {
                console.log("success")
                const invoice = new Blob(
                    [res.data],
                    { type: 'application/pdf' });

                //Build a URL from the file
                const fileURL = URL.createObjectURL(invoice);
                setLabelLoading(false)
                //Open the URL on new Window
                window.open(fileURL);
            }).catch(err => {
                setLabelLoading(false)
                console.error(err)
            })
    }
    return (
        <tr key={i} className={
                'border border-black  ' 
                + (i % 2 == 0 ? 'bg-slate-100 ' : '')
                + (order.attributes.status === "NEW" ? 'text-blue-600 ' : '')
            }
        >
            <td className='px-6 flex flex-col gap-2 text-left '>
                {order.attributes.status === "NEW" && <span className="bg-blue-700 rounded text-white w-12 text-center">NEW </span>}
                {order.attributes.orderID}
                <Link className="border text-center my-2 rounded bg-primary text-white hover:bg-primary-accent py-1" to={"/orders/" + order.attributes.orderID}>View Details</Link>
            </td>
            <td>{order.attributes.customerEmail}</td>
            <td>
                <ul className=''>
                    {order.attributes.items.map((item, i) => (
                        <li key={i}>
                            {item.product.brand} {item.product.model} {item.calibration ? <span className='font-semibold'>+ CAL</span> : ""}</li>
                    ))}
                </ul>
            </td>
            <td>
                <ul>
                    {order.attributes.items.map((item, i) => (
                        <li key={i}>{item.qty}</li>
                    ))}
                </ul>
            </td>
            <td>
                <ul>
                    {order.attributes.items.map((item, i) => (
                        <li key={i}>AED {(item.price * item.qty).toFixed(2)} {item.calibration ? "(Cal: AED "+ item.product.calibrationPrice +")" : null}</li>
                    ))}
                </ul>
            </td>
            <td>
                <ul className='flex flex-col gap-1 py-2'>
                    {/* <li>Sub Total: AED {order.attributes.subTotal} {(order.attributes.customerCountry !== "United Arab Emirates" && order.attributes.customerCountry !== "الإمارات العربية المتحدة") ? <span className='text-red-500'>(5% vat removed)</span> : null}</li> */}
                    <li>Sub Total: AED {parseFloat(order.attributes.subTotal?.toFixed(2))} {(order.attributes?.shippingDetails?.countryId !== "AE") ? <span className='text-red-500'>(5% vat removed)</span> : null}</li>
                    <li>Shipping: AED {order.attributes.shipping}</li>
                    <li>Total: AED {order.attributes.total}</li>
                    {order.attributes.paymentMode === "ONLINE" &&
                        <li>Network Status: {order.attributes.paymentStatus}</li>}
                    {order.attributes.paymentStatus === "AUTHORISED" && 
                        <li>
                            <button className=' rounded-md bg-blue-700 text-white px-4 py-1'>Capture</button>
                        </li>
                    }
                </ul>
                
            </td>
            <td>{order.attributes.paymentMode=='COD'?"COD":<>
            {(order.attributes.paymentMode==="ONLINE"&&order.attributes.paymentDetails.payment_method)==="AMAZON"?<span >AMAZON</span>:order.attributes.paymentMode==="ONLINE"?"NETWORK":""}
            </>}</td>
            <td>
                <select
                    value={newStatus}
                    onChange={e => setNewStatus(e.target.value)}
                    name="selectNewStatus"
                    className='border border-black px-2 py-1 rounded-sm'
                >
                    <option value="NEW">NEW</option>
                    <option value="PAYMENTPENDING">PAYMENT_PENDING</option>
                    <option value="CONFIRMED">Confirmed</option>
                    <option value="SHIPPED">Shipped</option>
                    <option value="OUT_FOR_DELIVERY">OUT_FOR_DELIVERY</option>
                    <option value="RETURNED_TO_ORIGIN">RETURNED_TO_ORIGIN</option>
                    <option value="EXCEPTION">EXCEPTION</option>
                    <option value="DELIVERED">Delivered</option>
                    <option value="RETURNED">RETURNED</option>
                    {/* <option value="OUT_FOR_DELIVERY">Out for delivery</option> */}
                    <option value="CANCELLED">CANCELLED</option>
                </select>

            </td>
            <td className="w-full flex flex-col gap-2 my-2">
                {invoiceLoading ?
                    <span>Loading</span>
                    :
                    <button onClick={() => downloadInvoice(order)} title={order.attributes.orderID + " Invoice"} className="inline-block border rounded border-black bg-white p-1">Download Invoice</button>
                }
                {labelLoading ?
                    <span>Loading</span>
                    :
                    <button onClick={() => downloadLabel(order.attributes.orderID)} title={order.attributes.orderID + " Label"} className="inline-block border rounded border-black bg-white p-1">Download label</button>
                }
                <button 
                    disabled={currentStatus === newStatus || statusLoading}
                    title={order.attributes.orderID + " Save changes"} 
                    onClick={handleStatusChange}
                    className="disabled:bg-gray-200 disabled:text-gray-400 inline-block border rounded bg-blue-500 text-white p-1"
                >
                    Save Changes
                </button>
            </td>
        </tr>
    )
}

export default OrderRow